<template>
    <div class="fangjiawp">
        <van-nav-bar
                title="房价查询"
                left-text="返回"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <van-form @submit="onSubmit" ref="fangjiaForm">
            <van-field
                    input-align="right"
                    :value="townText"
                    readonly
                    clickable
                    required
                    name="townId"
                    label="所在城镇"
                    placeholder="点击选择所在城镇"
                    @click="townShowPicker = true"
                    :rules="[{ required: true, message: '选择所在城镇' }]"
            >
                <template #right-icon>
                    <van-icon name="arrow"/>
                </template>
            </van-field>
            <van-popup v-model="townShowPicker" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="townColumns"
                        @confirm="townConfirm"
                        @cancel="townShowPicker = false"
                />
            </van-popup>
            <van-field
                    input-align="right"
                    :value="tdjbText"
                    readonly
                    clickable
                    required
                    name="tdjb"
                    label="土地级别"
                    placeholder="点击选择土地级别"
                    @click="tdjbShowPicker = true"
                    :rules="[{ required: true, message: '请选择土地级别' }]"
            >
                <template #button>
                    <div class="tdjbSelect" @click.stop="tdjbSelect">预览</div>
                </template>
                <template #right-icon>
                    <van-icon name="arrow"/>
                </template>
            </van-field>
            <van-popup v-model="tdjbShowPicker" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="tdjbColumns"
                        @confirm="tdjbConfirm"
                        @cancel="tdjbShowPicker = false"
                />
            </van-popup>
            <van-field
                    input-align="right"
                    :value="fclxText"
                    readonly
                    clickable
                    required
                    name="fclx"
                    label="房产类型"
                    placeholder="点击选择房产类型"
                    @click="fclxShowPicker = true"
                    :rules="[{ required: true, message: '选择房产类型' }]"
            >
                <template #right-icon>
                    <van-icon name="arrow"/>
                </template>
            </van-field>
            <van-popup v-model="fclxShowPicker" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="fclxColumns"
                        @confirm="fclxConfirm"
                        @cancel="fclxShowPicker = false"
                />
            </van-popup>
            <van-field
                    input-align="right"
                    v-model="formData.villageName"
                    required
                    name="villageName"
                    :label="formData.fclx == 1 ? '小区名称' : '位置'"
                    :placeholder="formData.fclx == 1 ? '小区名称' : '位置'"
                    :rules="[{ required: true, message: formData.fclx == 1 ? '请填写小区名称' : '请填写位置' }]"
            />
            <div v-if="formData.fclx == 1">
                <van-field
                        input-align="right"
                        :value="sfdtText"
                        readonly
                        clickable
                        required
                        name="sfdt"
                        label="是否为电梯房"
                        placeholder="点击选择是否为电梯房"
                        @click="sfdtShowPicker = true"
                        :rules="[{ required: true, message: '选择是否为电梯房' }]"
                >
                    <template #right-icon>
                        <van-icon name="arrow"/>
                    </template>
                </van-field>
                <van-popup v-model="sfdtShowPicker" position="bottom">
                    <van-picker
                            show-toolbar
                            :columns="sfdtColumns"
                            @confirm="sfdtConfirm"
                            @cancel="sfdtShowPicker = false"
                    />
                </van-popup>
                <van-field
                        input-align="right"
                        v-model="formData.szlc"
                        type="digit"
                        required
                        name="szlc"
                        label="所在层数"
                        placeholder="所在层数"
                        :rules="[{ required: true, message: '请填写所在层数' },{ validator: szlcValidator, message: '请输入正确楼层' }]"
                />
                <van-field
                        input-align="right"
                        v-model="formData.zcs"
                        type="digit"
                        required
                        name="zcs"
                        label="总层数"
                        placeholder="总层数"
                        :rules="[{ required: true, message: '请填写总层数' },{ validator:zcsValidator, message: '请输入正确楼层' }]"
                />
                <!--                <van-field-->
                <!--                        input-align="right"-->
                <!--                        :value="sfzjText"-->
                <!--                        readonly-->
                <!--                        clickable-->
                <!--                        required-->
                <!--                        name="sfzj"-->
                <!--                        label="是否为自建"-->
                <!--                        placeholder="点击选择是否为自建"-->
                <!--                        @click="sfzjShowPicker = true"-->
                <!--                        :rules="[{ required: true, message: '选择是否为自建' }]"-->
                <!--                >-->
                <!--                    <template #right-icon>-->
                <!--                        <van-icon name="arrow"/>-->
                <!--                    </template>-->
                <!--                </van-field>-->
                <!--                <van-popup v-model="sfzjShowPicker" position="bottom">-->
                <!--                    <van-picker-->
                <!--                            show-toolbar-->
                <!--                            :columns="sfzjColumns"-->
                <!--                            @confirm="sfzjConfirm"-->
                <!--                            @cancel="sfzjShowPicker = false"-->
                <!--                    />-->
                <!--                </van-popup>-->
            </div>
            <div v-if="formData.fclx == 1 || formData.fclx == 2 ||formData.fclx == 3">
                <van-field
                        input-align="right"
                        :value="sfzxText"
                        readonly
                        clickable
                        required
                        name="sfzx"
                        label="装修情况"
                        placeholder="点击选择装修情况"
                        @click="sfzxShowPicker = true"
                        :rules="[{ required: true, message: '选择装修情况' }]"
                >
                    <template #right-icon>
                        <van-icon name="arrow"/>
                    </template>
                </van-field>
                <van-popup v-model="sfzxShowPicker" position="bottom">
                    <van-picker
                            show-toolbar
                            :columns="sfzxColumns"
                            @confirm="sfzxConfirm"
                            @cancel="sfzxShowPicker = false"
                    />
                </van-popup>
                <van-field
                        input-align="right"
                        v-model="formData.jcnf"
                        type="digit"
                        required
                        name="jcnf"
                        label="建成年份"
                        placeholder="建成年份"
                        :rules="[{ required: true, message: '请填写建成年份' },{ validator:jcnfValidator, message: '请输入正确年份' }]"
                />
            </div>
            <div v-if="formData.fclx == 1 || formData.fclx == 2 ||formData.fclx == 3||formData.fclx == 5">
                <van-field
                        input-align="right"
                        v-model="formData.tdmj"
                        type="number"
                        required
                        name="tdmj"
                        label="土地面积"
                        placeholder="土地面积"
                        :rules="[{ required: true, message: '请填写土地面积' },{ validator:tdmjValidator, message: '请输入正确土地面积' }]"
                />
                <van-field
                        input-align="right"
                        v-model="formData.tdstnx"
                        type="digit"
                        required
                        name="tdstnx"
                        label="土地剩余年数"
                        placeholder="土地剩余年数"
                        :rules="[{ required: true, message: '请填写土地剩余年数' },{ validator: tdstnxValidator, message: '年数范围应为0-70' }]"
                />
                <van-field
                        input-align="right"
                        v-model="formData.jzmj"
                        type="number"
                        required
                        name="jzmj"
                        :label="formData.fclx == 5 ? '厂房建筑面积' : '建筑面积'"
                        :placeholder="formData.fclx == 5 ? '厂房建筑面积' : '建筑面积'"
                        :rules="[{ required: true, message: formData.fclx == 5 ? '请填写厂房建筑面积' : '请填写建筑面积' }, { validator:jzmjValidator, message: formData.fclx == 5 ? '请输入正确写厂房建筑面积' : '请输入正确建筑面积'}]"
                />
            </div>
            <div v-if="formData.fclx == 1 || formData.fclx == 2 ||formData.fclx == 3">
                <van-field
                        input-align="right"
                        :value="xqfText"
                        readonly
                        clickable
                        required
                        name="xqf"
                        label="所属学区"
                        placeholder="点击选择所属学区"
                        @click="xqfShowPicker = true"
                        :rules="[{ required: true, message: '选择所属学区' }]"
                >
                    <template #right-icon>
                        <van-icon name="arrow"/>
                    </template>
                </van-field>
                <van-popup v-model="xqfShowPicker" position="bottom">
                    <van-picker
                            show-toolbar
                            :columns="xqfColumns"
                            @confirm="xqfConfirm"
                            @cancel="xqfShowPicker = false"
                    />
                </van-popup>
                <div v-if="formData.xqf == '1.2'">
                    <van-field
                            input-align="right"
                            :value="dysxfjText"
                            readonly
                            clickable
                            required
                            name="dysxfj"
                            label="第一实小四周"
                            placeholder="点击选择是否为第一实小四周"
                            @click="dysxfjShowPicker = true"
                            :rules="[{ required: true, message: '选择是否为第一实小四周' }]"
                    >
                        <template #right-icon>
                            <van-icon name="arrow"/>
                        </template>
                    </van-field>
                    <van-popup v-model="dysxfjShowPicker" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="dysxfjColumns"
                                @confirm="dysxfjConfirm"
                                @cancel="dysxfjShowPicker = false"
                        />
                    </van-popup>
                </div>
            </div>
            <div v-if="formData.fclx == 2 || formData.fclx == 3">
                <van-field
                        input-align="right"
                        :value="jzlxText"
                        readonly
                        clickable
                        required
                        name="jzlx"
                        label="建筑类型"
                        placeholder="点击选择建筑类型"
                        @click="jzlxShowPicker = true"
                        :rules="[{ required: true, message: '选择建筑类型' }]"
                >
                    <template #right-icon>
                        <van-icon name="arrow"/>
                    </template>
                </van-field>
                <van-popup v-model="jzlxShowPicker" position="bottom">
                    <van-picker
                            show-toolbar
                            :columns="jzlxColumns"
                            @confirm="jzlxConfirm"
                            @cancel="jzlxShowPicker = false"
                    />
                </van-popup>
            </div>
            <div v-if="formData.fclx == 3 || formData.fclx == 4">
                <van-field
                        input-align="right"
                        v-model="formData.dmmj"
                        type="number"
                        required
                        name="dmmj"
                        label="店面面积"
                        placeholder="店面面积"
                        :rules="[{ required: true, message: '请填写店面面积' },{ validator:dmmjValidator, message: '请输入正确店面面积' }]"
                />
                <van-field
                        input-align="right"
                        v-model="formData.dmzj"
                        type="number"
                        required
                        name="dmzj"
                        label="月租金"
                        placeholder="月租金"
                        :rules="[{ required: true, message: '请填写月租金' },{ validator:dmzjValidator, message: '请输入正确月租金' }]"
                />
                <van-field
                        input-align="right"
                        v-model="formData.dmgcmj"
                        type="number"
                        required
                        name="dmgcmj"
                        label="店面隔层面积"
                        placeholder="店面隔层面积"
                        :rules="[{ required: true, message: '请填写店面隔层面积' },{ validator:dmgcmjValidator, message: '请输入正确店面隔层面积' }]"
                />
            </div>
            <div v-if="formData.fclx == 5">
                <van-field
                        input-align="right"
                        v-model="formData.shlmj"
                        type="number"
                        required
                        name="shlmj"
                        label="宿舍楼面积"
                        placeholder="宿舍楼面积"
                        :rules="[{ required: true, message: '请填写宿舍楼面积' },{ validator:shlmjValidator, message: '请输入正确宿舍楼面积' }]"
                />
            </div>
            <van-field
                    input-align="right"
                    v-model="formData.mobile"
                    type="tel"
                    required
                    name="mobile"
                    label="手机号码"
                    placeholder="手机号码"
                    :rules="[{ required: true, message: '请填写手机号码' },{ validator:mobileValidator, message: '请输入手机号码' }]"
            />
            <van-field
                    input-align="right"
                    v-model="formData.code"
                    type="number"
                    required
                    name="code"
                    label="验证码"
                    placeholder="验证码"
                    :rules="[{ required: true, message: '请填写验证码' }]"
            >
                <template #button>
                    <div class="tdjbSelect" :class="time == 0 ? '' : 'disable'" @click.stop="sendCode">发送验证码<span v-if="time > 0">({{time}}s)</span></div>
                </template>
            </van-field>
            <!--            <div style="margin: 16px;">-->
            <!--                <van-checkbox v-model="aggree" shape="square" style="font-size: 12px" icon-size="14">阅读并同意《相关条款》</van-checkbox>-->
            <!--            </div>-->
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">
                    提交
                </van-button>
            </div>
        </van-form>
        <van-popup v-model="popupShow" position="right" :style="{ width: '80%' }">
            <imgList @select="popupSelect"></imgList>
        </van-popup>
    </div>
</template>

<script>
    import {getTown, fangjiawp, sendCode} from '@/api/public'
    import {Toast} from 'vant';
    import imgList from "./imgList";

    export default {
        name: 'fangjiawp',
        components: {imgList},
        data() {
            return {
                popupShow: false,
                formData: {
                    townId: '',
                    villageName: '',
                    fclx: '1',
                    tdstnx: '',
                    sfdt: '',
                    // sfzj: '',
                    sfzx: '',
                    jcnf: '',
                    szlc: '',
                    zcs: '',
                    jzlx: '',
                    jzmj: '',
                    tdmj: '',
                    dmmj: '',
                    xqf: '',
                    dmzj: '',
                    dmgcmj: '0',
                    dysxfj: '',
                    shlmj: '',
                    tdjb: '1',
                    mobile: '',
                    code: '',
                },
                aggree: false,
                townText: '',
                townShowPicker: false,
                townColumns: [],
                fclxText: '套房',
                fclxShowPicker: false,
                fclxColumns: [
                    {text: '套房', value: 1,},
                    {text: '幢房', value: 2,},
                    {text: '沿街有店面的幢房', value: 3,},
                    {text: '店面', value: 4,},
                    {text: '厂房', value: 5,},
                ],
                sfdtText: '',
                sfdtShowPicker: false,
                sfdtColumns: [
                    {text: '是', value: 'true',},
                    {text: '否', value: 'false',},
                ],
                // sfzjText: '',
                // sfzjShowPicker: false,
                // sfzjColumns: [
                //     {text: '是', value: 'true',},
                //     {text: '否', value: 'false',},
                // ],
                sfzxText: '',
                sfzxShowPicker: false,
                sfzxColumns: [
                    {text: '精装修', value: '1000',},
                    {text: '普通装修', value: '800',},
                    {text: '毛坯', value: '0',},
                ],
                jzlxText: '',
                jzlxShowPicker: false,
                jzlxColumns: [
                    {text: '框架', value: '500',},
                    {text: '混合', value: '350',},
                ],
                xqfText: '',
                xqfShowPicker: false,
                xqfColumns: [
                    {text: '第一实小', value: '1.2',},
                    {text: '附小', value: '1.15',},
                    {text: '第二实小', value: '1.1',},
                    {text: '鼓楼实小', value: '1.08',},
                    {text: '红军小学', value: '1.05',},
                    {text: '其它', value: '1',},
                ],
                dysxfjText: '',
                dysxfjShowPicker: false,
                dysxfjColumns: [
                    {text: '是', value: 'true',},
                    {text: '否', value: 'false',},
                ],
                tdjbText: '一级地',
                tdjbShowPicker: false,
                tdjbColumns: [
                    {text: '一级地', value: '1',},
                    {text: '二级地', value: '2',},
                    {text: '三级地', value: '3',},
                    {text: '四级地', value: '4',},
                    {text: '五级地', value: '5',},
                ],
                time: 0,
            };
        },
        created() {
            this.getTown();
        },
        methods: {
            getTown() {
                getTown().then((res) => {
                    this.townColumns = res.data;
                    this.townColumns.forEach((town) => {
                        town.text = town.TownName;
                        if (town.TownName == '城区') {
                            this.townText = town.TownName;
                            this.formData.townId = town.Id;
                        }
                    })
                }).catch(e => {
                    console.log(e);
                })
            },
            onSubmit() {
                if (this.formData.fclx != 1) {
                    this.formData.sfdt = '';
                    this.formData.szlc = '';
                    this.formData.zcs = '';
                    // this.formData.sfzj = '';
                } else {
                    if (Number(this.formData.szlc) > Number(this.formData.zcs)) {
                        Toast.fail('所在层数不能大于总层数');
                        return false;
                    }
                }
                if (this.formData.fclx != 1 && this.formData.fclx != 2 && this.formData.fclx != 3) {
                    this.formData.sfzx = '';
                    this.formData.jcnf = '';
                    this.formData.xqf = '';
                    this.formData.dysxfj = '';
                } else {
                    if (this.formData.xqf != '1.2') {
                        this.formData.dysxfj = 'false';
                    }
                }
                if (this.formData.fclx != 1 && this.formData.fclx != 2 && this.formData.fclx != 3 && this.formData.fclx != 5) {
                    this.formData.tdmj = '';
                    this.formData.tdstnx = '';
                    this.formData.jzmj = '';
                }

                if (this.formData.fclx != 2 && this.formData.fclx != 3) {
                    this.formData.jzlx = '';
                }
                if (this.formData.fclx != 3 && this.formData.fclx != 4) {
                    this.formData.dmmj = '';
                    this.formData.dmzj = '';
                    this.formData.dmgcmj = '';
                }
                if (this.formData.fclx != 5) {
                    this.formData.shlmj = '';
                }
                fangjiawp(this.formData).then((res) => {
                    if (res.code == 1) {
                        this.$router.push({
                            path: '/result',
                            query: res.data
                        });
                    } else {
                        Toast.fail(res.msg);
                    }
                }).catch(e => {
                    console.log(e);
                })
            },
            townConfirm(value) {
                this.townText = value.text;
                this.formData.townId = value.Id;
                this.townShowPicker = false;
            },
            fclxConfirm(value) {
                this.fclxText = value.text;
                this.formData.fclx = value.value;
                this.fclxShowPicker = false;
            },
            sfdtConfirm(value) {
                this.sfdtText = value.text;
                this.formData.sfdt = value.value;
                this.sfdtShowPicker = false;
            },
            // sfzjConfirm(value) {
            //     this.sfzjText = value.text;
            //     this.formData.sfzj = value.value;
            //     this.sfzjShowPicker = false;
            // },
            sfzxConfirm(value) {
                this.sfzxText = value.text;
                this.formData.sfzx = value.value;
                this.sfzxShowPicker = false;
            },
            jcnfValidator(value) {
                let year = new Date().getFullYear();
                return Number(value) > 1950 && Number(value) <= year;
            },
            tdstnxValidator(value) {
                return Number(value) >= 0 && Number(value) <= 70;
            },
            szlcValidator(value) {
                return Number(value) >= 0;
            },
            zcsValidator(value) {
                return Number(value) >= 0;
            },
            jzlxConfirm(value) {
                this.jzlxText = value.text;
                this.formData.jzlx = value.value;
                this.jzlxShowPicker = false;
            },
            jzmjValidator(value) {
                return Number(value) >= 0;
            },
            tdmjValidator(value) {
                return Number(value) >= 0;
            },
            dmmjValidator(value) {
                return Number(value) >= 0;
            },
            xqfConfirm(value) {
                this.xqfText = value.text;
                this.formData.xqf = value.value;
                this.xqfShowPicker = false;
            },
            dmzjValidator(value) {
                return Number(value) > 0;
            },
            dmgcmjValidator(value) {
                return Number(value) >= 0;
            },
            dysxfjConfirm(value) {
                this.dysxfjText = value.text;
                this.formData.dysxfj = value.value;
                this.dysxfjShowPicker = false;
            },
            shlmjValidator(value) {
                return Number(value) >= 0;
            },
            mobileValidator(value) {
                return /^1(3|4|5|7|8|9|6)\d{9}$/i.test(value);
            },
            sendCode() {
                if ((/^1[3456789]\d{9}$/.test(this.formData.mobile))) {
                    if(this.time > 0){
                        return
                    }
                    this.time = 61;
                    this.sendCodeTime();
                    sendCode({mobile: this.formData.mobile}).then((res) => {
                        if (res.code == 1) {
                            Toast.success(res.msg);
                        } else {
                            Toast.fail(res.msg);
                        }
                    }).catch(e => {
                        console.log(e);
                    })
                } else {
                    Toast.fail('请输入正确手机号码');
                }
            },
            sendCodeTime() {
                this.time--;
                if (this.time > 0) {
                    setTimeout(() => {
                        this.sendCodeTime();
                    }, 1000)
                }
            },
            tdjbSelect() {
                this.popupShow = true;
                // this.$router.push({
                //     path: '/imgList',
                // });
            },
            tdjbConfirm(value) {
                this.tdjbText = value.text;
                this.formData.tdjb = value.value;
                this.tdjbShowPicker = false;
            },
            popupSelect(value, index) {
                this.tdjbText = value;
                this.formData.tdjb = index;
                this.popupShow = false;
            }
        }
    }
</script>
<style lang="less">
    .fangjiawp {
        .van-field__error-message {
            text-align: right;
        }

        .tdjbSelect {
            min-width: 1.6rem;
            height: 0.8rem;
            padding: 0 0.21333rem;
            font-size: 0.32rem;
            color: #fff;
            background-color: #07c160;
            border: 0.02667rem solid #07c160;
            position: relative;
            display: inline-block;
            box-sizing: border-box;
            margin: 0;
            line-height: 0.8rem;
            text-align: center;
            border-radius: 0.05333rem;
            cursor: pointer;
            transition: opacity .2s;
            -webkit-appearance: none;

            &.disable {
                opacity: 0.5;
            }
        }
    }
</style>
