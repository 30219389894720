<template>
    <div class="img-list">
        <van-cell :title="item" v-for="(item,index) in list" :key="index">
            <template #right-icon>
                <van-button type="primary" size="mini" @click="view(index)" style="margin-right: 5px;">预览</van-button>
<!--                <van-button type="info" size="mini" @click="select(index)">选择</van-button>-->
            </template>
        </van-cell>
        <!--        <van-row>-->
        <!--            <van-col span="6" v-for="(item,index) in list" :key="index" @click="view(index)">-->
        <!--                <van-image-->
        <!--                        width="20vw"-->
        <!--                        height="20vw"-->
        <!--                        fit="cover"-->
        <!--                        :src="pre + item + '.png'"-->
        <!--                />-->
        <!--                <div style="font-size: 12px;text-align: center">{{item}}</div>-->
        <!--            </van-col>-->
        <!--        </van-row>-->
    </div>
</template>

<script>
    import {ImagePreview} from 'vant';

    export default {
        name: 'imgList',
        components: {
            [ImagePreview.Component.name]: ImagePreview.Component,
        },
        data() {
            return {
                pre: 'http://193.112.195.55:8868/Images/',
                list: [
                    '城区厂房土地级别',
                    '城区土地级别',
                    '大禾土地级别',
                    '东留土地级别',
                    '民主土地级别',
                    '十方厂房土地级别',
                    '十方土地级别',
                    '桃溪土地级别',
                    '万安土地级别',
                    '武东土地级别',
                    '下坝土地级别',
                    '湘店土地级别',
                    '象洞土地级别',
                    '岩前厂房土地级别',
                    '岩前土地级别',
                    '永平土地级别',
                    '中堡土地级别',
                    '中赤土地级别',
                    '中山土地级别',
                ]
            }
        },
        methods: {
            view(index) {
                let arr = [];
                this.list.forEach((item) => {
                    arr.push(this.pre + item + '.png')
                })
                ImagePreview({
                    images:arr ,
                    startPosition: index,
                    closeable: true,
                });
            },
            select(index) {
                this.$emit('select', this.list[index], index + 1);
            }

        }
    }
</script>

<style scoped lang="less">

</style>
